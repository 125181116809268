// src/apolloClient.js
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';

const client = new ApolloClient({
    link: new HttpLink({
        uri: "https://api.armenianautoshow.com/v1/graphql", // Replace with your GraphQL endpoint
    }),
    cache: new InMemoryCache(),
});

export default client;
