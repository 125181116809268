// src/graphql/queries.js
import {gql} from '@apollo/client';


// Define the fragment
const VISITOR_DETAILS_FRAGMENT = gql`
  fragment VisitorDetails on visitors {
    companyName
    createdAt
    email
    firstName
    id
    interestsIds
    lastName
    phoneNumber
    updatedAt
  }
`;

// export const GET_VISITORS = gql`
//   query GetVisitors {
//     visitors {
//       ...VisitorDetails
//     }
//   }
//       ${VISITOR_DETAILS_FRAGMENT}
// `;


export const GET_VISITOR_INTERESTS = gql`
 query GetVisitorInterests {
  visitorInterests{
    translations
    id
  }
}
`;

export const GET_ZONES = gql`
 query GetZones {
   zones {
    name
    id
    availableNumbers
  }
}
`;

// query MyQuery {
//       visitorInterests {
//             id
//             translations
//             type
//             createdAt
//             updatedAt
//       }
// }

export const ADD_VISITOR = gql`
  mutation AddVisitor(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $notes: String!,
    $companyName: String!,
    $interestsIds: json!,
    $phoneNumber: String!
  ) {
    insertVisitors(
      objects: [{
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        notes: $notes,
        companyName: $companyName,
        phoneNumber: $phoneNumber,
        interestsIds: $interestsIds
      }]
    ) {
      affected_rows
    }
  }
`;


export const ADD_EXHIBITOR = gql`
  mutation AddExhibitor(
    $brands: String!,
    $companyName: String!,
    $email: String!,
    $industry: String!,
    $message: String!,
    $notes: String!,
    $phoneNumber: String!,
    $website: String!,
    $zoneId: uuid!,
    $zoneNumbers: jsonb!
  ) {
    insertExhibitors(
      objects: [{
        brands: $brands,
        companyName: $companyName,
        email: $email,
        industry: $industry,
        message: $message,
        notes: $notes,
        phoneNumber: $phoneNumber,
        website: $website,
        zoneId: $zoneId,
        zoneNumbers: $zoneNumbers
      }]
    ) {
      affected_rows
    }
  }
`;

